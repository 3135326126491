<template>
  <div class="bind-success" >
    <div v-if="!faceStatus" >
      <!-- 成功图标 -->
      <div class="icon-container">
<!--        <van-icon name="clock-o" color="#07c160" size="80" />-->

        <van-loading type="spinner" color="#07c160"  size="80" />
      </div>

      <!-- 绑定成功消息 -->
      <p class="message">等待人脸结果通知</p>

      <!-- 提示文字 -->
      <p class="hint">请耐心等待。。。。</p>
    </div>

    <div v-if="faceStatus">
      <!-- 成功图标 -->
      <div class="icon-container">
<!--        <van-icon name="clock-o" color="#07c160" size="80" />-->
        <van-loading type="spinner" color="#07c160"  size="80" />
      </div>

      <!-- 绑定成功消息 -->
      <p class="message">等待签章结果通知</p>

      <!-- 提示文字 -->
      <p class="hint">请耐心等待。。。。</p>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {
  loanBindBankCardStartSignPost,
  nationalBrokerProfileBankCardInfo,
} from "@/api";

export default {
  // 在这里可以添加逻辑

  data() {
    return {
      form: {},
      signResult: {},
      loading:true,
      faceStatus:false,
      status:false,
      //
    };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {


    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 20 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileBankCardInfo();

        if(this.form.bindStatus!=1) {
          Toast.clear();
          if (!this.form.faceStatus) {
            Toast("人脸认证失败，请重新发起认证");
            setTimeout(() => {
              this.$router.replace('/bindAgree');

            }, 1000);
          }else {
             this.faceStatus = this.form.faceStatus;
            if (this.form.contractSuccess) {
              this.$router.replace('/bankCardBind');
            }else{
              try {
              this.signResult = await loanBindBankCardStartSignPost();

                this.$router.replace('/bankCardBind');

              } catch (err) {
                Toast.clear();
                Toast(err);
                setTimeout(() => {
                  this.$router.replace('/bindAgree');
                }, 1000);

              }
            }
          }
        }else{
          setTimeout(() => {
            this.$router.replace('/index');
          }, 1000);
        }

      } catch (err) {
        Toast.clear();
        Toast(err);
      }

    },

  },
};
</script>

<style scoped>
.agreement {
  padding: 2vw;

}
.bind-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.icon-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  font-size: 1.5rem;
  color: #07c160; /* 成功消息文本颜色 */
  text-align: center; /* 水平居中对齐 */
}

.hint {
  font-size: 1rem;
  color: #333; /* 提示文字颜色 */
  margin-top: 10px;
  text-align: center; /* 水平居中对齐 */
}

/* 可以根据需要添加其他样式 */
</style>
