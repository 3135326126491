<template>
  <div class="min_h_100_vh bg_col_F5F7F9">
    <div>
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/MZWvAgujPhUFqeYqKZbQ.png"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per">
        <div class="m_0_16 m_t_24">
          <div class="font_24 col_fff">银行卡绑定</div>
          <div class="font_14 col_fff opa_70_per m_t_8">Bind bank card</div>
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_b_neg_120"></div>



    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16">
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">身份证号</div>

        <div
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
        >{{form.certNo}}</div>

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">账户名称</div>

        <div
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
        >{{form.bankAccountName}}</div>

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">银行预留手机号</div>

        <div
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
        >{{form.reservedMobile}}</div>

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">银行账号</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankAccount"
          type="text"
          placeholder="请输入"
          maxlength="30"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">银行名称</div>

        <!-- 2.2.4废弃 -->
        <!-- <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankName"
          type="text"
          placeholder="请输入"
          maxlength="20"
        /> -->
        <div
          :class="`font_14 ${
            bankList.find((it) => it.value == form.interBankNo) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow: true }"
        >
          {{
            (bankList.find((it) => it.value == form.interBankNo) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 银行选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow"
        :columns="bankList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              bankName: v.label,
              interBankNo: v.value,
            })
        "
      />


      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>
      <van-field
        v-model="form.smsCode"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button size="small" @click="submit()" type="info">{{ smsCountDownSecond ? `${smsCountDownSecond}秒后重试` : '发送验证码' }}</van-button>
        </template>
      </van-field>


      <!--  -->
    </div>

    <!-- 提交 -->
    <div class="dis_flex m_0_16 m_t_32">
      <van-button class="flex_1 font_16" type="info" @click="save()"
        >提交</van-button
      >
    </div>

    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import {
  nationalBrokerProfileBindBankCardPost,
  nationalBrokerProfileBankCardInfo,
  brokerProfileBankSupportList, sendsms,
} from '@/api/index';

export default {
  components: {
    PickEnum: () => import('@/components/PickEnum'),
  },
  data() {
    return {
      form: {},
      result:{},

      bankList: [],

      /**
       * 短信倒计时
       */
      smsCountDownSecond: 0,
      smsTimeout: null,
      //
    };
  },
  computed: {},
  mounted() {
    this.getBankList();

    this.getDetail();

    //
  },
  methods: {

    // 倒计时
    smsCountDown(seconds) {
      this.smsCountDownSecond = --seconds;

      if (seconds > 0) {
        this.smsTimeout = setTimeout(() => {
          this.smsCountDown(seconds);
        }, 1000);
      }

      //
    },

    // 提交
    async submit() {

      if (this.smsCountDownSecond > 0) return;

      try {


        if (!this.form.bankAccount) {
          throw '请输入银行账户';
        }

        if (!this.form.bankAccountName) {
          throw '请输入银行账号';
        }

        if (!this.form.bankName) {
          throw '请选择银行';
        }


        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

     await sendsms({
          bankAccount: this.form.bankAccount,
          bankAccountName: this.form.bankAccountName,
          bankName: this.form.bankName,
          interBankNo: this.form.interBankNo,
          certImgFront: this.form.certImgFront,
          certImgBack: this.form.certImgBack,
          certNo: this.form.certNo,
          reservedMobile: this.form.reservedMobile,
        })

        Toast.clear();

        this.smsCountDown(60);

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 提交
    async save() {
      try {
        if (!this.form.bankAccount) {
          throw '请输入银行账户';
        }

        if (!this.form.bankAccountName) {
          throw '请输入银行账号';
        }

        if (!this.form.bankName) {
          throw '请选择银行';
        }

        if (!this.form.smsCode) {
          throw '验证码';
        }


        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.result = await nationalBrokerProfileBindBankCardPost({
          smsCode: this.form.smsCode,
        });


        // if(this.result.code==200){

          Toast.success('绑定成功');
          setTimeout(() => {
            this.$router.replace('/index');
          }, 1000);
        // }else {
        //   Toast(this.result.msg);
        // }
        //
      } catch (err) {
        Toast(err);
      }

      //
    },


    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileBankCardInfo();
        if(this.form.bindStatus==1){

          this.$router.replace('/index');
        }
        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 银行
    async getBankList() {
      const data = await brokerProfileBankSupportList();

      this.bankList = data.map((item) => {
        return {
          ...item,
          label: item.bankName,
          value: item.interBankNo,
        };
      });



      //
    },

    //
  },
};
</script>
