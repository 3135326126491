<template>
  <div class="bind-success" >
    <div v-if="form && form.bindStatus==1">
      <!-- 成功图标 -->
      <div class="icon-container">
        <van-icon name="passed" color="#07c160" size="80" />
      </div>

      <!-- 绑定成功消息 -->
      <p class="message">绑定成功</p>

      <wx-open-launch-weapp
        username="gh_e145c72f13f0"
        path="/pages/goods/goods"
        style="display:block;"
      >
        <script type="text/wxtag-template">
        <button  style="width:200px;height:80px;">打开小程序</button>
        </script>
      </wx-open-launch-weapp>
<!--      <button @click="redirectToMiniProgram">跳转到小程序</button>-->
      <div class="box c-weapp-box">
<!--       <wx-weapp-box path="/pages/goods/goods"/>-->
        <img class="img" src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220718/rxHbijhZUaEXzxchWqsM.png" />
      </div>

      <!-- 提示文字 -->
      <p class="hint">感谢您的支持，绑定完成！</p>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import wx from 'weixin-js-sdk'
// import wxWeappBox from './wx-weapp-box.vue'
import {
  nationalBrokerProfileBankCardInfo,
} from "@/api";

export default {
  // 在这里可以添加逻辑

  // components: { wxWeappBox },
  data() {
    return {
      form: {},
      wechat: {},
      loading:true,
      //
    };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {

    // redirectToMiniProgram() {
    //   const appid = 'wxc11b438d01a54863'; // 替换为目标小程序的 AppID
    //   const path = 'pages/goods/goods'; // 替换为小程序内部的页面路径（可选）
    //   const url = `wxa://pages/index?appid=${appid}&path=${path}`;
    //   window.location.href = url;
    // },
    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileBankCardInfo();
        if(this.form.bindStatus!=1){

          if(this.form.contractSuccess){
            this.$router.replace('/bankCardBind');
            return;
          }
          this.$router.replace('/bindAgree');
        }else{

          this.wechat = this.form.wechat;
          console.log(this.wechat);

          this.wechat={
            // debug: true, // 开启调试模式
            appId: this.wechat.appId, // 必填,公众号的唯一标识
            timestamp: this.wechat.timestamp, // 必填,生成签名的时间戳
             nonceStr: this.wechat.nonceStr, // 必填,生成签名的随机串
             signature: this.wechat.signature, // 必填,签名
            jsApiList: ["chooseImage"], // 必填,需要使用的JS接口列表
            openTagList:['wx-open-launch-weapp']  // 可选，需要使用的开放标签列表
          }
          console.log(this.wechat);
          wx.config(this.wechat);

        // wx.miniProgram.navigateTo({
        //     appId: 'wxc11b438d01a54863', // 目标小程序的AppID
        //     path: 'pages/goods/goods',  // 目标小程序内部的页面路径
        //     success(res) {
        //       console.log('成功跳转到小程序', res);
        //     },
        //     fail(error) {
        //       console.error('跳转失败', error);
        //     },
        //   });

        }
        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

    },

  },
};
</script>

<style scoped>
.agreement {
  padding: 2vw;

}
.bind-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.icon-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  font-size: 1.5rem;
  color: #07c160; /* 成功消息文本颜色 */
  text-align: center; /* 水平居中对齐 */
}

.hint {
  font-size: 1rem;
  color: #333; /* 提示文字颜色 */
  margin-top: 10px;
  text-align: center; /* 水平居中对齐 */
}

/* 可以根据需要添加其他样式 */
  .c-weapp-box{
    overflow: hidden;
    position: relative;
    .img{
      width: 100%;
      height: 100%;
    }
  }
</style>
