import * as http from '@/utils/http';




// 根据手机号码发送验证码
export const commonNationalBrokerSmsPost = async (data) => {
  const res = await http.post(`/common/national/broker/sms`, data);
  return res.data;
};



// 登录
export const oauthTokenPost = async ({
                                       mobile, sms_code, shortCode, grant_type = 'auto_login_v2',
                                     }) => {
  const data = await http.post(`/oauth/token`
    + `?client_id=dingdang.h5&client_secret=zcsqMGH2JTdYwH0hSp`
    + `&grant_type=${grant_type}&mobile=${mobile}&sms_code=${sms_code}&shortCode=${shortCode}`);
  return data;
};

// 登出
export const authRevokeDelete = async (token) => {
  const res = await http.del(`/repay/logout`
    + `?token=${token}`);
  return res?.data;
};


// 银行卡详情
export const nationalBrokerProfileBankCardInfo = async () => {
  const res = await http.get(`/loan/bank/card/info`);
  return res?.data || {};
};



// 获取支持的银行列表
export const brokerProfileBankSupportList = async () => {
  const res = await http.get(`/api/national/broker/profile/bank/support/list`);
  return res?.data || [];
};

// 绑定银行卡
export const sendsms = async (data) => {
  const res = await http.post(`/loan/bank/send/sms`, data);
  return res?.data  || {};
};


// 绑定银行卡
export const nationalBrokerProfileBindBankCardPost = async (data) => {
  const res = await http.post(`/loan/bind/confirm`, data);
  return res?.data  || {};
};

// 绑定银行卡
export const loanBindBankCardStartH5FacePost = async (data) => {
  const res = await http.post(`loan/bank/card/startH5Face`, data);
  return res?.data || {};
};

// 绑定银行卡
export const loanBindBankCardStartSignPost = async (data) => {
  const res = await http.post(`loan/bank/card/startSign`, data);
  return res?.data  || {};
};

