<template>
  <div class="agreement" >
    <!-- 顶部标题栏 -->
    <van-nav-bar
      title="咨询、评估服务合同书"
    ></van-nav-bar>

    <van-row class="agreement-content">
      <van-col span="24">

        <!-- 这里插入您的协议内容 -->
        <div>融资人（以下称“甲方”）： {{form.bankAccountName}}</div>
        <div>服务公司（以下称“乙方”）：重庆得物智家科技有限公司</div>
        <p>甲、乙双方依据《民法典》及相关法律规定，平等、自愿、合法地达成如下协议：</p>
        <p>
          一、甲方拟向陕西海富融资租赁有限公司（以下简称“出租人”）申请汽车融资租赁业务，签署编号为 <span class="underline">{{form.contractNo}}</span> 的《汽车融资租赁合同》（以实际签署的合同名为准以下简称“主合同”）。甲方现委托乙方为其车辆提供咨询、评估服务。
        </p>
        <p>二、甲方向乙方支服务费用的方式</p>
        <p>1、甲方同意乙方有权向其收取服务费。双方确认，按照主合同中租赁价款本金总额的1%计算，甲方应支付的服务费总额为人民币<span class="underline">{{form.withholdPrice}}</span>元。甲方应付服务费为出租人向甲方发放租赁价款之日趸交支付。</p>
        <p>2、甲方不可撤销地授权乙方有权或有权指令第三方支付机构按照本合同约定从甲方在主合同约定的甲方还款账户或甲方在合作方运营的平台绑定的银行账户中（以下统称“甲方账户”）划扣相应的服务费、保证金及违约金（如有）。</p>
        <p>三、甲方不得违反支付费用的约定，否则从迟延之日起每日按拖欠数额的0.1%支付违约金以及乙方为追偿上述款项而支出的合理款项（包括但不限于保全担保费、律师费）。</p>
        <p>四、甲方单方解除合同，乙方有权要求甲方支付上述服务费用，并且承担乙方为追偿上述款项而支出的合理款项（包括但不限于保全担保费、律师费）。</p>
        <p>五、本合同双方严格遵守，若出现纠纷协商不成双方均有权向乙方所在地人民法院起诉。</p>
        <p>六、本合同一式贰份双方各持壹份，经甲方签字及乙方盖章后生效。</p>
        <div class="m_t_24">甲方（签名）：</div>
        <div class="m_t_32">乙方（签章）：</div>
        <div class="m_t_24">签署日期：{{form.nowDate}}</div>

      </van-col>
    </van-row>

    <!-- 勾选框 -->
    <van-checkbox v-model="agreed" shape="round" class="agree-checkbox">
      我已阅读并同意签署以上合同
    </van-checkbox>
    <!-- 同意按钮 -->
    <van-button
      type="info"
      round
      block
      :disabled="!agreed || sendStatus"
      @click="agree"
      class="agree-button"
    >
      签署并继续
    </van-button>
  </div>
</template>

<script>


import {Toast} from "vant";
import {loanBindBankCardStartH5FacePost, nationalBrokerProfileBankCardInfo} from "@/api";

export default {
  data() {
    return {
      agreed: false, // 用户是否同意协议
      form:{},
      sendStatus: false,
      signResult:{}
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {

    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileBankCardInfo();
        if(this.form.contractSuccess){

          this.$router.replace('/bankCardBind');
        }
        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

    },

    async agree() {

      try {
        if (this.agreed) {
          this.sendStatus = true;
          this.signResult = await loanBindBankCardStartH5FacePost();
          this.sendStatus = false;
          window.top.location.href=this.signResult.startFaceUrl;
          //if(signResult)
        }
      } catch (err) {
        Toast(err);
      }
    },
  },
};
</script>

<style scoped>
.agreement {
  padding: 2vw;
}

.agreement-content {
  padding: 2vw;
}

.agreement-content p{
  text-indent: 2em;
}

.agree-checkbox {
  margin-top: 10px;
}
.agree-button {
  margin-top: 20px;
}
.underline{
  text-decoration:underline
}
</style>
